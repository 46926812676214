import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-header-card',
  templateUrl: './dashboard-header-card.component.html',
  styleUrls: ['./dashboard-header-card.component.scss']
})
export class DashboardHeaderCardComponent implements OnInit {

  @Input() isActive = false;
  @Input() title = '';
  @Input() description = '';
  @Input() iconClass = '';
  @Input() colorClass = '';
  @Input() counter = 0;

  @Output() toggle = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}

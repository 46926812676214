<app-container hasFixedWidth="true">
    <app-alert *ngIf="this.error" type="error">
        {{error}}
    </app-alert>

    <form *ngIf="this.formGroup" [formGroup]="formGroup" (ngSubmit)="submit()" class="mb-10">
        <div class="sm:grid sm:grid-cols-12 gap-4 md:grid-cols-12">
            <div *ngIf="this.showAvatar" class="sm:col-span-12">
                <app-avatar-edit [avatarUrl]="this.avatarUrl" (croppedOuput)="this.image = $event"></app-avatar-edit>
            </div>

            <div class="sm:col-span-12">
                <p class="text-gray-500 font-bold">ID: {{this.humanFriendlyId}}</p>
            </div>

            <div class="sm:col-span-6">
                <span class="text-gray-500 font-bold">First name *</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="firstname" class="form-control mt-1 block w-full" type="text" formControlName="firstname"
                        name="firstname" placeholder="John" required />
                </div>

                <p *ngIf="this.personId ? firstname.hasError('required') : firstname.touched && firstname.hasError('required')"
                    class="mt-2 text-sm text-red-600" id="firstname-error">
                    The firstname is required.</p>
                <p *ngIf="this.inputSubtitles && this.inputSubtitles.firstname"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.firstname === this.firstname.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.firstname}} 
                    <button *ngIf="this.inputSubtitles.firstname !== this.firstname.value" type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.firstname.setValue(this.inputSubtitles.firstname)">
                        Apply
                    </button>
                </p>
            </div>

            <div class="sm:col-span-6">
                <span class="text-gray-500 font-bold">Last name *</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="lastname" class="form-control mt-1 block w-full" type="text" formControlName="lastname"
                        name="lastname" placeholder="Doe" required />
                </div>

                <p *ngIf="this.personId ? lastname.hasError('required') : lastname.touched && lastname.hasError('required')"
                    class="mt-2 text-sm text-red-600" id="lastname-error">
                    The lastname is required.</p>
                <p *ngIf="this.inputSubtitles && this.inputSubtitles.lastname"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.lastname === this.lastname.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.lastname}}
                    <button
                        *ngIf="this.inputSubtitles.lastname !== this.lastname.value"
                        type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.lastname.setValue(this.inputSubtitles.lastname)">
                        Apply
                    </button>
                </p>
            </div>

            <div class="sm:col-span-6">
                <span class="text-gray-500 font-bold">Gender *</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <ng-select [items]="genders" [bindLabel]="'name'" [bindValue]="'id'" formControlName="gender"
                        placeholder="Select a gender"></ng-select>
                </div>
                <p *ngIf="this.personId ? gender.hasError('required') : gender.touched && gender.hasError('required')"
                    class="mt-2 text-sm text-red-600" id="gender-error">
                    gender is required.</p>
                <p *ngIf="this.inputSubtitles && this.inputSubtitles.gender != null"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.gender === this.gender.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.gender | gender}}
                    <button *ngIf="this.inputSubtitles.gender !== this.gender.value" type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.gender.setValue(this.inputSubtitles.gender)">
                        Apply
                    </button>
                </p>
            </div>

            <div class="sm:col-span-6">
                <span class="text-gray-500 font-bold">Nationality *</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <ng-select [items]="countries" [bindLabel]="'country'" [bindValue]="'code'"
                        formControlName="country" placeholder="Select a country"></ng-select>
                </div>

                <p *ngIf="this.personId ? country.hasError('required') : country.touched && country.hasError('required')"
                    class="mt-2 text-sm text-red-600" id="country-error">
                    The country is required.</p>
                <p *ngIf="this.inputSubtitles && this.inputSubtitles.countryCode"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.countryCode === this.country.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.countryCode}}
                    <button *ngIf="this.inputSubtitles.countryCode !== this.country.value" type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.country.setValue(this.inputSubtitles.countryCode)">
                        Apply
                    </button>
                </p>
            </div>

            <div class="sm:col-span-6">
                <span class="text-gray-500 font-bold">Birth date</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true" formControlName="birthdate"
                        [firstDayOfWeek]="1" [start]="'Decade'" name="birthdate" format="dd.MM.yyyy"
                        [placeholder]="'dd.MM.yyyy'" floatLabelType='Never'>
                    </ejs-datepicker>
                </div>

                <p *ngIf="this.inputSubtitles && this.inputSubtitles.birthdate"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.birthdate === this.birthdate.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.birthdate | date : 'dd.MM.yyyy'}}
                    <button *ngIf="this.inputSubtitles.birthdate !== this.birthdate.value" type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.birthdate.setValue(this.inputSubtitles.birthdate)">
                        Apply
                    </button>
                </p>
            </div>

            <div class="col-span-6 col-start-1">
                <span class="text-gray-500 font-bold">Email</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="email" class="form-control mt-1 block w-full" type="text" formControlName="email"
                        name="email" placeholder="john.doe@example.com" />
                </div>

                <p *ngIf="this.personId ? email.hasError('email') : email.touched && email.hasError('email')"
                    class="mt-2 text-sm text-red-600" id="email-error">
                    E-Mail format is invalid.</p>
                <p *ngIf="this.inputSubtitles && this.inputSubtitles.email"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.email === this.email.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.email}}
                    <button *ngIf="this.inputSubtitles.email !== this.email.value" type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.email.setValue(this.inputSubtitles.email)">
                        Apply
                    </button>
                </p>
            </div>

            <div class="sm:col-span-6">
                <span class="text-gray-500 font-bold">Phone</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="phone" class="form-control mt-1 block w-full" type="text" formControlName="phone"
                        name="phone" placeholder="+41 79 123 45 67" />
                </div>

                <p *ngIf="this.inputSubtitles && this.inputSubtitles.phone"
                    class="mt-2 text-sm font-bold"
                    [ngClass]="this.inputSubtitles.phone === this.phone.value ? 'text-indigo-500' : 'text-red-500'">
                    Existing value: {{this.inputSubtitles.phone}}
                    <button *ngIf="this.inputSubtitles.phone !== this.phone.value" type="button"
                        class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        (click)="this.phone.setValue(this.inputSubtitles.phone)">
                        Apply
                    </button>
                </p>
            </div>
        </div>

        <br>
        
        <ng-container *ngIf="this.isTournamentDetailsSectionVisible">
            <div class="mt-5 grid grid-cols-2 mb-5">
                <div class="col-span-1">
                    <h3 class="text-lg font-bold text-gray-9009 sm:truncate">
                        {{this.tournament?.name}}
                    </h3>
                </div>
                <div class="col-span-1 text-right">
                    <button type="button" *ngIf="this.showButtons && !this.isUnregistered" class="justify-self-end btn btn-danger-outline" (click)="this.onUnregister()">Unregister from tournament</button>
                </div>
              </div>

            <ng-container *ngIf="this.isUnregistered">
                <app-alert type="warning">
                    This person has been unregistered from {{this.tournament?.name}}.
                    <br>
                    <br>
                    <button type="button" class="btn btn-white" (click)="this.onRegisterAgain()">
                        Register again for {{this.tournament?.name}}
                    </button>
                </app-alert>
            </ng-container>

            <div class="sm:grid sm:grid-cols-2 sm:gap-4 mt-3 md:grid-cols-2">
                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">BWF ID</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="bwfId" class="form-control mt-1 block w-full" type="text" formControlName="bwfId"
                            name="bwfId" placeholder="012356789" />
                    </div>

                    <p *ngIf="this.inputSubtitles && this.inputSubtitles.bwfId"
                        class="mt-2 text-sm font-bold"
                        [ngClass]="this.inputSubtitles.bwfId === this.bwfId.value ? 'text-indigo-500' : 'text-red-500'">
                        Existing value: {{this.inputSubtitles.bwfId}}
                        <button *ngIf="this.inputSubtitles.bwfId !== this.bwfId.value" type="button"
                            class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            (click)="this.bwfId.setValue(this.inputSubtitles.bwfId)">
                            Apply
                        </button>
                    </p>
                </div>

                <div class="col-span-1  col-start-1">
                    <span class="text-gray-500 font-bold">Roles</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <ng-select #roles [items]="rolesOfTournament" [bindLabel]="'name'" [multiple]="true" [bindValue]="'id'"
                            formControlName="roles" placeholder="Select role(s)">
                        </ng-select>
                    </div>

                    <p *ngIf="this.inputSubtitles && this.inputSubtitles.role"
                        class="mt-2 text-sm font-bold text-indigo-500">
                        Submitted value: {{this.inputSubtitles.role}}
                    </p>
                </div>

                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Organization</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <ng-select [items]="organizations" [compareWith]="compareWith" [bindLabel]="'nameWithType'"
                            [bindValue]="'id'" formControlName="organization" placeholder="Select an organization">
                        </ng-select>
                    </div>

                    <p *ngIf="this.inputSubtitles && this.inputSubtitles.organization"
                        class="mt-2 text-sm font-bold text-indigo-500">
                        Submitted value: {{this.inputSubtitles.organization}}
                    </p>
                </div>

                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Arrival date</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true" formControlName="arrivesAt"
                            [firstDayOfWeek]="1" name="arrivesAt" format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'"
                            floatLabelType='Never'>
                        </ejs-datepicker>
                    </div>
                </div>

                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Departure date</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true" formControlName="departsAt"
                            [firstDayOfWeek]="1" name="departsAt" format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'"
                            floatLabelType='Never'>
                        </ejs-datepicker>
                    </div>
                </div>
            </div>

            <!-- Additional Tournament Fields -->
            <div class="d-block mt-5 sm:grid sm:grid-cols-12 sm:gap-4 mt-3 md:grid-cols-12"
                *ngIf="this.tournaments.length > 0">
                <span class="text-gray-500 font-bold col-span-12">Organizational data</span>

                <div class="col-start-1 col-span-6">
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="parkingcardNeeded" type="checkbox" formControlName="isParkingCardNeeded"
                                name="parkingcardNeeded"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="parkingcardNeeded" class="font-medium text-gray-700">Parking card needed</label>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start" *ngIf="isParkingCardNeeded.value === true">
                        <div class="flex items-center h-5">
                            <input id="parkingcardIssued" type="checkbox" formControlName="isParkingCardIssued"
                                name="parkingcardIssued"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="parkingcardIssued" class="font-medium text-gray-700">Parking card issued</label>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="visaNeeded" type="checkbox" formControlName="isVisaNeeded" name="visaNeeded"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="visaNeeded" class="font-medium text-gray-700">Visa needed</label>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="badgeNeeded" type="checkbox" formControlName="isBadgeNeeded" name="badgeNeeded"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="badgeNeeded" class="font-medium text-gray-700">Badge needed</label>
                        </div>
                    </div>
                    <div *ngIf="this.isBadgeNeeded.value === true" class="mt-5 ml-5">
                        <div *ngFor="let accessTypeCheckBox of accessTypesOfPerson.controls; let i = index" class="mt-1 relative flex items-start pl-4"
                            formArrayName="accessTypesOfPerson">
                            <div class="flex items-center h-5">
                                <input [attr.id]="i" type="checkbox" [formControlName]="i"
                                    class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                            </div>
                            <div class="ml-3 text-sm leading-5">
                                <label [attr.for]="i" class="font-medium text-gray-700">{{this.accessTypes[i].name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="transportNeededOnArrival" type="checkbox" formControlName="isTransportNeededOnArrival"
                                name="transportNeededOnArrival"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="transportNeededOnArrival" class="font-medium text-gray-700">Transport needed on arrival</label>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="transportNeededOnDeparture" type="checkbox" formControlName="isTransportNeededOnDeparture"
                                name="transportNeededOnDeparture"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="transportNeededOnDeparture" class="font-medium text-gray-700">Transport needed on departure</label>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="hotelNeeded" type="checkbox" formControlName="isHotelNeeded" name="hotelNeeded"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="hotelNeeded" class="font-medium text-gray-700">Hotel needed</label>
                        </div>
                    </div>
                    <div *ngIf="this.isHotelNeeded.value === true" class="mt-5 ml-5">
                        <span class="text-gray-500 font-bold">Preferred bedroom type</span>
                        <div class="mt-1 relative">
                            <div class="mt-4 flex items-center">
                                <input id="bedroom-size-single" type="radio" name="preferredHotelRoomType"
                                    [value]="hotelRoomType.SINGLE" formControlName="preferredHotelRoomType"
                                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                                <label class="ml-3" for="bedroom-size-single">
                                    <span
                                        class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomType.SINGLE}}</span>
                                </label>
                            </div>
                            <div class="mt-4 flex items-center">
                                <input id="bedroom-size-twin" type="radio" name="preferredHotelRoomType"
                                    [value]="hotelRoomType.TWIN" formControlName="preferredHotelRoomType"
                                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                                <label class="ml-3" for="bedroom-size-twin">
                                    <span
                                        class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomType.TWIN}}</span>
                                </label>
                            </div>
                            <div class="mt-4 flex items-center">
                                <input id="bedroom-size-two" type="radio" name="preferredHotelRoomType"
                                    [value]="hotelRoomType.TWOBEDS" formControlName="preferredHotelRoomType"
                                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                                <label class="ml-3" for="bedroom-size-two">
                                    <span
                                        class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomType.TWOBEDS}}</span>
                                </label>
                            </div>
                            <div class="mt-4 flex items-center">
                                <input id="bedroom-size-three" type="radio" name="preferredHotelRoomType"
                                    [value]="hotelRoomType.TRIPLEBEDS" formControlName="preferredHotelRoomType"
                                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                                <label class="ml-3" for="bedroom-size-three">
                                    <span
                                        class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomType.TRIPLEBEDS}}</span>
                                </label>
                            </div>
                        </div>
                        <br />
                        <span class="text-gray-500 font-bold">Hotel expenses</span>
                        <div class="mt-1 relative">
                            <div class="mt-4 flex items-center">
                                <input id="isFreeHotelCheckbox" type="checkbox" value="1" formControlName="isFreeHotel"
                                    class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                                <label class="ml-3" for="isFreeHotelCheckbox">
                                    <span class="block text-sm leading-5 font-medium text-gray-700">Free hotel</span>
                                </label>
                            </div>
                            <div *ngIf="isFreeHotel.value === false">
                                <div class="mt-4 flex items-center">
                                    <input id="isHotelInvoicePaidCheckbox" type="checkbox" value="1"
                                        formControlName="isHotelInvoicePaid"
                                        class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                                    <label class="ml-3" for="isHotelInvoicePaidCheckbox">
                                        <span class="block text-sm leading-5 font-medium text-gray-700">Invoice Paid</span>
                                    </label>
                                </div>
                                <br />
                                <div class="mt-3 grid gap-4 grid-cols-2">
                                    <div class="col-span-1">
                                        <span class="block text-sm leading-5 font-medium text-gray-700">Invoice
                                            number</span>
                                        <div class="mt-1 relative rounded-md shadow-sm">
                                            <input id="invoiceNumber" class="form-control mt-1 block w-full" type="text"
                                                formControlName="invoiceNumber" name="invoiceNumber"
                                                placeholder="01234567" />
                                        </div>
                                    </div>
                                    <div class="col-span-1" *ngIf="isHotelInvoicePaid.value === true">
                                        <span class="block text-sm leading-5 font-medium text-gray-700">Payment date</span>
                                        <div class="mt-1 relative rounded-md shadow-sm">
                                            <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true"
                                                formControlName="paidAt" [firstDayOfWeek]="1" name="paidAt"
                                                format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'" floatLabelType='Never'>
                                            </ejs-datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="pcrTestReceived" type="checkbox" formControlName="isPcrTestReceived"
                                name="pcrTestReceived"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="pcrTestReceived" class="font-medium text-gray-700">PCR test received
                                (negative)</label>
                        </div>
                    </div>
                    <div *ngIf="isPcrTestReceived.value === true"
                        class="sm:grid sm:grid-cols-2 sm:gap-4 mt-3 md:grid-cols-2">
                        <div class="sm:col-span-1">
                            <span class="block text-sm leading-5 font-medium text-gray-700">Test result received at</span>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true"
                                    formControlName="pcrTestReceivedAt" [firstDayOfWeek]="1" name="pcrTestReceivedAt"
                                    format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'" floatLabelType='Never'>
                                </ejs-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="antigenTestReceived" type="checkbox" formControlName="isAntigenTestReceived"
                                name="antigenTestReceived"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                            <label for="antigenTestReceived" class="font-medium text-gray-700">Antigen test received
                                (negative)</label>
                        </div>
                    </div> -->
                    <!-- <div *ngIf="isAntigenTestReceived.value === true"
                        class="sm:grid sm:grid-cols-2 sm:gap-4 mt-3 md:grid-cols-2">
                        <div class="sm:col-span-1">
                            <span class="block text-sm leading-5 font-medium text-gray-700">Test result received at</span>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true"
                                    formControlName="antigenTestReceivedAt" [firstDayOfWeek]="1"
                                    name="antigenTestReceivedAt" format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'"
                                    floatLabelType='Never'>
                                </ejs-datepicker>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-start-7 col-span-6">
                    <div class="mb-3 w-auto">
                        <label for="comment" class="form-label inline-block mb-2 text-gray-700">Comments</label>
                        <textarea class="form-control block w-full mt-1" id="comment" formControlName="comment"
                            rows="6" maxLength="4000" placeholder="Add a comment..."></textarea>
                    </div>
                    <p *ngIf="this.inputSubtitles && this.inputSubtitles.tournamentFields && this.inputSubtitles.tournamentFields.length > 0 && this.inputSubtitles.tournamentFields[0].comment"
                        class="mt-2 text-sm font-bold"
                        [ngClass]="this.inputSubtitles.tournamentFields[0].comment === this.comment.value ? 'text-indigo-500' : 'text-red-500'">
                        Existing value:<br>
                        {{this.inputSubtitles.tournamentFields[0].comment}}
                        <br>
                        <button *ngIf="this.inputSubtitles.tournamentFields[0].comment !== this.comment.value" type="button"
                            class="bg-slate-500 ml-1 text-white active:bg-slate-600 font-bold uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            (click)="this.comment.setValue(this.inputSubtitles.tournamentFields[0].comment)">
                            Apply
                        </button>
                    </p>
                </div>
            </div>
        </ng-container>

        <div *ngIf="this.showButtons" class="d-block mt-5 mb-10">
            <span class="inline-flex rounded-md shadow-sm">
                <button backButton type="button" class="btn btn-white mr-2">
                    Cancel
                </button>
                <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary">
                    {{ this.personId ? 'Update' : 'Create' }}
                </button>
            </span>
        </div>

        <ng-container *ngIf="!this.isTournamentDetailsSectionVisible">
            <h3 class="text-lg font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate mt-5">
                {{this.tournament?.name}}
            </h3>
            <app-alert type="info">
                This person is not yet part of {{this.tournament?.name}}.
            </app-alert>
            <button type="button" class="btn btn-primary-outline" (click)="this.isTournamentDetailsSectionVisible = true">
                Register for {{this.tournament?.name}}
            </button>
        </ng-container>
    </form>
</app-container>
<li class="relative col-span-1 flex shadow-sm rounded-md">
    <div [ngClass]="this.colorClass"
      class="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm leading-5 font-medium rounded-l-md">
      <span [ngClass]="this.iconClass" class="mr-2 text-lg"></span>
      {{this.counter}}
    </div>

    <div
      class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
      <div class="flex-1 px-4 py-2 text-sm leading-5 truncate">
        <h3 class="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
          {{this.title}}
        </h3>
        <p class="text-gray-500">{{this.description}}</p>
      </div>
      <div class="flex" style="height:100%">
        <button *ngIf="!this.isActive" (click)="this.toggle.emit(true); this.isActive = !this.isActive"
          class="pr-2 pl-2 inline-flex items-center justify-center text-indigo-400 bg-transparent hover:text-indigo-500 focus:outline-none focus:text-indigo-500">
          Show
        </button>
        <button *ngIf="this.isActive" (click)="this.toggle.emit(false); this.isActive = !this.isActive "
          class="pr-2 pl-2 inline-flex items-center justify-center text-white hover:text-gray-200 focus:outline-none focus:text-gray-200 bg-indigo-400">
          Hide
        </button>
      </div>
    </div>
  </li>
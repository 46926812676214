<app-container [hasFixedWidth]="false">
  <header>
    <h1 class="text-2xl font-semibold text-gray-900">
      Dashboard
    </h1>
  </header>

  <div>
    <div class="my-8">
      <ul class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 mt-3">
        <!-- <app-dashboard-header-card title="Covid tests"
          [description]="this.incompleteCovidTestsCounter + ' persons without negative test result'" color="red"
          [counter]="this.incompleteCovidTestsCounter" iconClass="fas fa-vial"
          (toggle)="this.toggleTask($event, this.DashboardTask.COVID_TESTS)">
        </app-dashboard-header-card> -->

        <app-dashboard-header-card title="Visa" [counter]="this.incompleteVisasCounter"
          [description]="this.incompleteVisasCounter + ' person(s) need(s) a visa'" colorClass="bg-orange-500" 
          iconClass="fas fa-passport" (toggle)="this.toggleTask($event, this.DashboardTask.VISAS)">
        </app-dashboard-header-card>
  
        <app-dashboard-header-card title="Parking cards" colorClass="bg-lime-400"
          [description]="this.incompleteParkingCardsCounter + ' person(s) need(s) a parking card'"
          [counter]="this.incompleteParkingCardsCounter" iconClass="fas fa-parking"
          (toggle)="this.toggleTask($event, this.DashboardTask.PARKING_CARDS)">
        </app-dashboard-header-card>

        <app-dashboard-header-card title="Entries & Partners"
          [description]="this.incompleteEntriesCounter + ' entry/entries invalid'" colorClass="bg-cyan-400"
          [counter]="this.incompleteEntriesCounter" iconClass="fas fa-user-friends"
          (toggle)="this.toggleTask($event, this.DashboardTask.ENTRIES)">
      </app-dashboard-header-card>

        <app-dashboard-header-card title="Hotel rooms" [counter]="this.incompleteHotelBookingsCounter"
          [description]="this.incompleteHotelBookingsCounter + ' person(s) need(s) a hotel room'" colorClass="bg-indigo-400"
          iconClass="fas fa-door-open" (toggle)="this.toggleTask($event, this.DashboardTask.HOTEL_ROOMS)">
        </app-dashboard-header-card>


        <app-dashboard-header-card title="Transports (Arrival)" [counter]="this.incompleteTransportBookingsArrivalCounter"
          [description]="this.incompleteTransportBookingsArrivalCounter + ' person(s) need(s) a transport'" colorClass="bg-amber-400"
          iconClass="fas fa-plane-arrival" (toggle)="this.toggleTask($event, this.DashboardTask.TRANSPORTS_ARRIVAL)">
        </app-dashboard-header-card>

        <app-dashboard-header-card title="Transports (Departure)" [counter]="this.incompleteTransportBookingsDepartureCounter"
          [description]="this.incompleteTransportBookingsDepartureCounter + ' person(s) need(s) a transport'" colorClass="bg-green-400"
          iconClass="fas fa-plane-departure" (toggle)="this.toggleTask($event, this.DashboardTask.TRANSPORTS_DEPARTURE)">
        </app-dashboard-header-card>

        <app-dashboard-header-card title="Photos"
          [description]="this.incompletePhotosCounter + ' person(s) have no photo'" colorClass="bg-blue-400"
          [counter]="this.incompletePhotosCounter" iconClass="fas fa-portrait"
          (toggle)="this.toggleTask($event, this.DashboardTask.PHOTO)">
        </app-dashboard-header-card>

        <app-dashboard-header-card title="Badges" [counter]="this.incompleteBadgesCounter"
          [description]="this.incompleteBadgesCounter + ' badge(s) not yet printed'" colorClass="bg-purple-500" 
          iconClass="fas fa-id-card" (toggle)="this.toggleTask($event, this.DashboardTask.BADGES)">
        </app-dashboard-header-card>
      </ul>
    </div>

    <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide mt-5">Persons</h2>

    <ng-container *ngIf="this.gridData">
      <ejs-grid #grid defaultGridSettings [dataSource]='gridData' (rowSelected)="onRowSelected($event)">
        <e-columns>
          <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
            <ng-template #template let-data>
              <div class="flex items-center">
                <img class="h-10 w-10 rounded-full" [src]="data.avatarUrl" alt="Photo">
              </div>
            </ng-template>
          </e-column>
          <e-column field='humanFriendlyId' headerText="ID" type="number" width="100"></e-column>
          <e-column field='bwfId' headerText="BWF ID" type="string" width="100"></e-column>
          <e-column field='firstname' headerText="Firstname" type="string"></e-column>
          <e-column field='lastname' headerText="Lastname" type="string"></e-column>
          <e-column field='birthdate' [visible]="false" headerText="Birthdate" type="date" [format]="formatSettings"></e-column>
          <e-column field='phone' [visible]="false" headerText="Phone" type="string"></e-column>
          <e-column field='email' [visible]="false" headerText="Email" type="string"></e-column>
          <e-column field='countryCode' headerText="Country" type="string"></e-column>
          <e-column field='organization.nameWithType' headerText="Organization" type="string"></e-column>
          <e-column field='role' headerText="Roles" type="string"></e-column>
          <e-column headerText='Tasks' [showInColumnChooser]='false' width="250">
            <ng-template #template let-data>
              <div class="flex items-center text-gray-300">
                <!-- <span [class.text-green-400]="this.isCovidTestTaskComplete(data)"
                  [class.text-red-500]="this.isCovidTestTaskIncomplete(data)" title="Needs negative COVID test result"
                  class="fas fa-vial mr-2 text-lg"></span> -->
                <span [class.text-green-400]="this.isVisaTaskComplete(data)"
                  [class.text-red-500]="this.isVisaTaskIncomplete(data)" title="Needs a visa"
                  class="fas fa-passport mr-2 text-lg"></span>
                <span [class.text-green-400]="this.isParkingCardTaskComplete(data)"
                  [class.text-red-500]="this.isParkingCardTaskIncomplete(data)" title="Needs a parking card"
                  class="fas fa-parking mr-2 text-lg"></span>
                <span [class.text-green-400]="this.isEntryTaskComplete(data)"
                  [class.text-red-500]="this.isEntryTaskIncomplete(data)" title="Needs a partner"
                  class="fas fa-user-friends mr-2 text-lg"></span>
                <span [class.text-green-400]="this.isHotelTaskComplete(data)"
                  [class.text-red-500]="this.isHotelTaskIncomplete(data)" title="Needs a hotel"
                  class="fas fa-door-open mr-2 text-lg"></span>
                <span [class.text-green-400]="this.isTransportOnArrivalTaskComplete(data)"
                  [class.text-red-500]="this.isTransportOnArrivalTaskIncomplete(data)" title="Needs a transport on arrival"
                  class="fas fa-plane-arrival mr-2 text-lg"></span>
                <span [class.text-green-400]="this.isTransportOnDepartureTaskComplete(data)"
                  [class.text-red-500]="this.isTransportOnDepartureTaskIncomplete(data)" title="Needs a transport on arrival"
                  class="fas fa-plane-departure mr-2 text-lg"></span>
                <span [class.text-green-400]="data.hasImage"
                  [class.text-red-500]="!data.hasImage" title="Needs a photo"
                  class="fas fa-portrait mr-2 text-lg"></span>
                <span [class.text-green-400]="this.isBadgeTaskComplete(data)"
                  [class.text-red-500]="this.isBadgeTaskIncomplete(data)" title="Needs a badge"
                  class="fas fa-id-card mr-2 text-lg"></span>
              </div>
            </ng-template>
          </e-column>
          <e-column headerText='Details' [showInColumnChooser]='false' textAlign='Right' width=220>
            <ng-template #template let-data>
              <a [routerLink]="['/persons', data.id]" class="text-indigo-500">Details</a>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </ng-container>

  </div>
</app-container>
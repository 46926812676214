import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { Person } from '@person/models/person.model';
import { PersonRoleHelperService } from '@person/services/person-role-helper.service';
import { DisciplineHelper } from '@team/components/helpers/discipline.helper';
import { GenderHelper } from '@person/components/helpers/gender.helper';
import { PersonService } from 'app/person/services/person.service';

@Component({
  selector: 'app-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss']
})
export class PersonListComponent implements OnInit {

  personRoleId: RoleIdentifier = null;
  tournamentId = null;
  data = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy' };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private personService: PersonService,
    private personRoleHelper: PersonRoleHelperService,
  ) { }

  get isPlayerPage(): boolean {
    return this.personRoleId === RoleIdentifier.PLAYER;
  }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.personRoleId = this.personRoleHelper.getRoleByRoute();
    this.loadPersons();
  }

  loadPersons(): void {
    const join = this.isPlayerPage ? ['organization', 'teams', 'teams.persons'] : ['organization'];
    this.tournamentId ? join.push('tournamentRoles', 'tournamentRoles.role') : null;

    const sort = 'lastname,ASC';

    let filter = null;
    if (this.personRoleId != null && this.tournamentId != null) {
      filter = [
        `tournamentRoles.roleId||$eq||${this.personRoleId}`,
        `tournamentRoles.tournamentId||$eq||${this.tournamentId}`
      ];
    } else if (this.tournamentId != null) {
      filter = [
        `tournamentRoles.tournamentId||$eq||${this.tournamentId}`
      ];
    }

    this.personService.get({ filter, sort, join }).subscribe((res) => {
      if (this.tournamentId != null) {
        res = res.map(person => {
          person['isUnregistered'] = person.tournamentRoles.some(role => role.roleId === RoleIdentifier.UNREGISTERED);
          person['isHotelNeeded'] = person.tournamentFields?.length > 0 && person.tournamentFields[0].isHotelNeeded ? 'yes' : 'no';
          person['isTransportNeededOnArrival'] = person.tournamentFields?.length > 0 && person.tournamentFields[0].isTransportNeededOnArrival ? 'yes' : 'no';
          person['isTransportNeededOnDeparture'] = person.tournamentFields?.length > 0 && person.tournamentFields[0].isTransportNeededOnDeparture ? 'yes' : 'no';
          person['isBadgeNeeded'] = person.tournamentFields?.length > 0 && person.tournamentFields[0].isBadgeNeeded ? 'yes' : 'no';
          person['discipline'] = person.teams?.length > 0 ? DisciplineHelper.getDisciplineAbbreviation(person.teams[0].discipline) : '';
          person['genderAbbreviation'] = GenderHelper.getGenderAbbreviation(person.gender);
          // person['isPcrTestReceived'] = person.tournamentFields?.length > 0 && person.tournamentFields[0].isPcrTestReceived && person.tournamentFields[0].pcrTestReceivedAt != null ? 'yes' : 'no';
          // person['isAntigenTestReceived'] = person.tournamentFields?.length > 0 && person.tournamentFields[0].isAntigenTestReceived && person.tournamentFields[0].antigenTestReceivedAt != null ? 'yes' : 'no';
          person['partner'] = this.getPartner(person);
          person['roles'] = person.tournamentRoles?.map(tournamentRole => tournamentRole.role?.name).join(', ');

          return person;
        })
      }
      this.data = res;
    });
  }

  getPartner(person: Person): string {
    if (person.teams == null
      || person.teams.length === 0
      || person.teams[0].persons == null
      || person.teams[0].length === 0) {
      return '';
    }

    const partner = person.teams[0].persons[0].id !== person.id ? person.teams[0].persons[0] : person.teams[0].persons[1];
    return partner == null ? '' : `${partner.lastname} ${partner.firstname}`;
  }

  onRowSelected(row): void {
    this.router.navigate([row.data.id], { relativeTo: this.route });
  }

}
